import ApiService from './ApiService'

export async function apiUpdateThemeCustomization(payload) {
    return ApiService.fetchData({
        url: `/customization/theme`,
        method: 'post',
        data: payload,
    })
}

export async function apiGetThemeCustomization() {
    return ApiService.fetchData({
        url: `/customization/theme`,
        method: 'get',
    })
}
