import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { themeConfig } from 'configs/theme.config'
import logo from '../../assets/svg/dlogo.svg'
import {
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_STACKED_SIDE,
    NAV_MODE_TRANSPARENT,
    NAV_MODE_LIGHT,
    NAV_MODE_DARK,
    NAV_MODE_THEMED,
    MODE_DARK,
    MODE_LIGHT,
    LAYOUT_TYPE_DECKED,
} from 'constants/theme.constant'
import {
    apiGetThemeCustomization,
    apiUpdateThemeCustomization,
} from 'services/ThemeService'
import {
    openErrorNotification,
    openSuccessNotification,
} from 'components/shared/Notify'
const initialNavMode = () => {
    if (
        themeConfig.layout.type === LAYOUT_TYPE_MODERN &&
        themeConfig.navMode !== NAV_MODE_THEMED
    ) {
        return NAV_MODE_TRANSPARENT
    }
    return themeConfig.navMode
}
const initialState = {
    themeColor: themeConfig.themeColor,
    direction: themeConfig.direction,
    mode: themeConfig.mode,
    primaryColorLevel: themeConfig.primaryColorLevel,
    panelExpand: themeConfig.panelExpand,
    navMode: initialNavMode(),
    layout: themeConfig.layout,
    horizontalLogo: logo,
    verticalLogo: logo,
    primaryTextColor: themeConfig.primaryTextColorPalette[0],
    primaryTextColorPalette: themeConfig.primaryTextColorPalette,
    secondaryTextColor: themeConfig.secondaryTextColorPalette[0],
    secondaryTextColorPalette: themeConfig.secondaryTextColorPalette,
    primaryBackgroundColor: themeConfig.primaryBackgroundColorPalette[0],
    primaryBackgroundColorPalette: themeConfig.primaryBackgroundColorPalette,
    secondaryBackgroundColor: themeConfig.secondaryBackgroundColorPalette[0],
    secondaryBackgroundColorPalette:
        themeConfig.secondaryBackgroundColorPalette,
}
const availableNavColorLayouts = [
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
]
export const getThemeCustomization = createAsyncThunk(
    'theme/getThemeCustomization',
    async () => {
        const response = await apiGetThemeCustomization()
        return response.data
    }
)
export const updateThemeCustomization = createAsyncThunk(
    'theme/updateThemeCustomization',
    async (payload) => {
        const response = await apiUpdateThemeCustomization(payload)
        return response.data
    }
)
export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setDirection: (state, action) => {
            state.direction = action.payload
        },
        setMode: (state, action) => {
            const availableColorNav = availableNavColorLayouts.includes(
                state.layout.type
            )
            if (
                availableColorNav &&
                action.payload === MODE_DARK &&
                state.navMode !== NAV_MODE_THEMED
            ) {
                state.navMode = NAV_MODE_DARK
            }
            if (
                availableColorNav &&
                action.payload === MODE_LIGHT &&
                state.navMode !== NAV_MODE_THEMED
            ) {
                state.navMode = NAV_MODE_LIGHT
            }
            state.mode = action.payload
        },
        setLayout: (state, action) => {
            state.cardBordered = action.payload === LAYOUT_TYPE_MODERN
            if (action.payload === LAYOUT_TYPE_MODERN) {
                state.navMode = NAV_MODE_TRANSPARENT
            }
            const availableColorNav = availableNavColorLayouts.includes(
                action.payload
            )
            if (availableColorNav && state.mode === MODE_LIGHT) {
                state.navMode = NAV_MODE_LIGHT
            }
            if (availableColorNav && state.mode === MODE_DARK) {
                state.navMode = NAV_MODE_DARK
            }
            state.layout = {
                ...state.layout,
                ...{ type: action.payload },
            }
        },
        setPreviousLayout: (state, action) => {
            state.layout.previousType = action.payload
        },
        setSideNavCollapse: (state, action) => {
            state.layout = {
                ...state.layout,
                ...{ sideNavCollapse: action.payload },
            }
        },
        setNavMode: (state, action) => {
            if (action.payload !== 'default') {
                state.navMode = action.payload
            } else {
                if (state.layout.type === LAYOUT_TYPE_MODERN) {
                    state.navMode = NAV_MODE_TRANSPARENT
                }
                const availableColorNav = availableNavColorLayouts.includes(
                    state.layout.type
                )
                if (availableColorNav && state.mode === MODE_LIGHT) {
                    state.navMode = NAV_MODE_LIGHT
                }
                if (availableColorNav && state.mode === MODE_DARK) {
                    state.navMode = NAV_MODE_DARK
                }
            }
        },
        setPanelExpand: (state, action) => {
            state.panelExpand = action.payload
        },
        setThemeColor: (state, action) => {
            state.themeColor = action.payload
        },
        setThemeColorLevel: (state, action) => {
            state.primaryColorLevel = action.payload
        },
        setHorizontalLogo: (state, action) => {
            state.horizontalLogo = action.payload
        },
        setVerticalLogo: (state, action) => {
            state.verticalLogo = action.payload
        },
        setPrimaryTextColor: (state, action) => {
            state.primaryTextColor = action.payload
        },
        setPrimaryTextColorPalette: (state, action) => {
            if (state.primaryTextColorPalette?.includes(action.payload)) return
            state.primaryTextColorPalette = [
                ...state.primaryTextColorPalette,
                action.payload,
            ]
        },
        removePrimaryTextColorFromPalette: (state, action) => {
            state.primaryTextColorPalette =
                state.primaryTextColorPalette.filter(
                    (color) => color !== action.payload
                )
        },
        setSecondaryTextColor: (state, action) => {
            state.secondaryTextColor = action.payload
        },
        setSecondaryTextColorPalette: (state, action) => {
            if (state.secondaryTextColorPalette?.includes(action.payload))
                return
            state.secondaryTextColorPalette = [
                ...state.secondaryTextColorPalette,
                action.payload,
            ]
        },
        removeSecondaryTextColorFromPalette: (state, action) => {
            state.secondaryTextColorPalette =
                state.secondaryTextColorPalette.filter(
                    (color) => color !== action.payload
                )
        },
        setPrimaryBackgroundColor: (state, action) => {
            state.primaryBackgroundColor = action.payload
        },
        setPrimaryBackgroundColorPalette: (state, action) => {
            if (state.primaryBackgroundColorPalette?.includes(action.payload))
                return
            state.primaryBackgroundColorPalette = [
                ...state.primaryBackgroundColorPalette,
                action.payload,
            ]
        },
        removePrimaryBackgroundColorFromPalette: (state, action) => {
            state.primaryBackgroundColorPalette =
                state.primaryBackgroundColorPalette.filter(
                    (color) => color !== action.payload
                )
        },
        setSecondaryBackgroundColor: (state, action) => {
            state.secondaryBackgroundColor = action.payload
        },
        setSecondaryBackgroundColorPalette: (state, action) => {
            if (state.secondaryBackgroundColorPalette?.includes(action.payload))
                return
            state.secondaryBackgroundColorPalette = [
                ...state.secondaryBackgroundColorPalette,
                action.payload,
            ]
        },
        removeSecondaryBackgroundColorFromPalette: (state, action) => {
            state.secondaryBackgroundColorPalette =
                state.secondaryBackgroundColorPalette.filter(
                    (color) => color !== action.payload
                )
        },
    },
    extraReducers: {
        [getThemeCustomization.fulfilled]: (state, action) => {
            const theme = action.payload.result[0]
            state.horizontalLogo = theme?.horizontalLogo?.link
        },
        [updateThemeCustomization.pending]: (state) => {
            state.loading = true
        },
        [updateThemeCustomization.rejected]: (state, action) => {
            state.loading = false
            const { payload } = action
            let message
            if (payload?.message) {
                message = payload?.message
            } else {
                message = 'Sorry an error occurred'
            }
            openErrorNotification(message)
        },
        [updateThemeCustomization.fulfilled]: (state, action) => {
            state.loading = false
            openSuccessNotification('Theme updated successfully')
            // window.location.href = ''
        },
    },
})
export const {
    setDirection,
    setMode,
    setLayout,
    setSideNavCollapse,
    setNavMode,
    setPanelExpand,
    setThemeColor,
    setThemeColorLevel,
    setPreviousLayout,
    setHorizontalLogo,
    setPrimaryTextColor,
    setPrimaryTextColorPalette,
    removePrimaryTextColorFromPalette,
    setSecondaryTextColor,
    setSecondaryTextColorPalette,
    removeSecondaryTextColorFromPalette,
    setPrimaryBackgroundColor,
    setPrimaryBackgroundColorPalette,
    removePrimaryBackgroundColorFromPalette,
    setSecondaryBackgroundColor,
    setSecondaryBackgroundColorPalette,
    removeSecondaryBackgroundColorFromPalette,
} = themeSlice.actions
export default themeSlice.reducer
