import React, { forwardRef, useState } from 'react'
import classNames from 'classnames'
import { useTabs } from './context'
import useCallbackRef from '../hooks/useCallbackRef'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const TabNav = forwardRef((props, ref) => {
    const {
        value: valueProp,
        disabled,
        className,
        icon,
        children,
        ...rest
    } = props

    const { value, onValueChange, variant } = useTabs()
    const isSelected = valueProp === value

    const { secondaryBackgroundColor } = useSelector((state) => state.theme)
    const [isHovered, setIsHovered] = useState(false)

    const onTabNavClick = useCallbackRef(() => {
        if (!isSelected && !disabled) {
            onValueChange(valueProp)
        }
    })

    const tabNavClass = classNames(
        'tab-nav',
        `tab-nav-${variant}`,
        isSelected && `tab-nav-active `,

        isSelected && variant === 'pill' && ` dark:text-gray-100`,
        disabled && 'tab-nav-disabled',
        className
    )

    return (
        <div
            className={tabNavClass}
            role="tab"
            aria-selected={isSelected}
            ref={ref}
            onClick={onTabNavClick}
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
            {...rest}
            style={{
                color: (isSelected || isHovered) && secondaryBackgroundColor,
                backgroundColor:
                    isSelected &&
                    variant === 'pill' &&
                    secondaryBackgroundColor,
                borderColor:
                    isSelected &&
                    variant === 'underline' &&
                    secondaryBackgroundColor,
            }}
        >
            {icon && <div className="tab-nav-icon">{icon}</div>}
            {children}
        </div>
    )
})

TabNav.propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default TabNav
