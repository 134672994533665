import { Button } from "../ui";
import { HiX } from "react-icons/hi";

const ViewMoreContentSection = ({
    title,
    icon,
    children,
    titleSize: Title = 'h6',
    ticketClose,
}) => {
    return (
        <div className="flex mb-5">
            <div className="text-2xl">{icon}</div>
            <div className="w-full ml-2 rtl:mr-2">
                <div className="flex justify-between">
                    <Title>{title}</Title>
                    {ticketClose && (
                        <Button
                            size="sm"
                            shape="circle"
                            variant="plain"
                            icon={<HiX className="text-lg" />}
                            onClick={() => ticketClose()}
                        />
                    )}
                </div>
                {children}
            </div>
        </div>
    )
}

export default ViewMoreContentSection
