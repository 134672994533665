import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {apiGetCollection} from "../../services/CollectionService";
import {SCREENS} from "../../utils/collectionUtils";

export const getAppCollection = createAsyncThunk(
    'collections/getAppCollection',
    async ({types, screen = SCREENS.INCIDENT}) => {
        const response = await apiGetCollection({
            screen,
            types,
        })
        return response.data
    }
)
export const collectionSlice = createSlice({
    name: 'collections',
    initialState: {
        countries: [],
        collection: {},
    },
    reducers: {
        setMerchants: (state, action) => {
            state.merchants = action.payload
        },
        setSelectedMerchant: (state, action) => {
            state.selectedMerchant = action.payload
        }
    },
    extraReducers: {
        // Get Collection
        [getAppCollection.fulfilled]: (state, action) => {
            state.collection = action.payload.result
        },
    },

})

export const { setMerchants, setSelectedMerchant } =
    collectionSlice.actions

export default collectionSlice.reducer
