import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ActionLink = (props) => {
    const { children, className, to, href = '', ...rest } = props

    const { secondaryBackgroundColor } = useSelector((state) => state.theme)

    const classNameProps = {
        className: classNames('hover:underline', className),
    }

    return to ? (
        <Link
            to={to}
            {...classNameProps}
            style={{ color: secondaryBackgroundColor }}
            {...rest}
        >
            {children}
        </Link>
    ) : (
        <a href={href} {...classNameProps} {...rest}>
            {children}
        </a>
    )
}

ActionLink.propTypes = {
    themeColor: PropTypes.bool,
    to: PropTypes.string,
}

ActionLink.defaultProps = {
    themeColor: true,
}

export default ActionLink
