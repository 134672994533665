export const SCREENS = {
    OBSERVATION: 'observations',
    INCIDENT: 'incidents',
    INSPECTIONS: 'inspections',
    COURSES: 'courses',
    REGULATION: 'regulations',
    CERTIFICATION: 'certifications',
    STOREST: 'stores-transactions',
    STORES: 'stores',
}

export const COLLECTION_TYPES = {
    ItemType: 'ItemType',
    Severity: 'Severity',
    Site: 'Site',
    Status: 'Status',
    WorkRelated: 'WorkRelated',
    Employee: 'Employee',
    Risk: 'Risk',
    Location: 'Location',
    InjuryCase: 'InjuryCase',
    Illness: 'Illness',
    Concern: 'Concern',
    JobType: 'JobType',
    DirectReport: 'DirectReport',
    EmploymentType: 'EmploymentType',
    Function: 'Function',
    Concerns: 'Concern',
    GoverningBody: 'GoverningBody',
    FormType: 'FormType',
    ActionStatus: 'ActionStatus',
}

export const ACTION_MODEL_TYPES = {
    Inspection: 'Inspection',
    Audit: 'Audit',
}

export const ANALYTICS_TYPES = {
    COURSE: 'course',
    INCIDENT: 'incident',
    INSPECTION: 'inspection',
    OBSERVATION: 'observation',
}
